import { Box, BoxProps } from "../Box/Box";

export const validStackComponents = [
  "a",
  "article",
  "div",
  "form",
  "header",
  "label",
  "li",
  "main",
  "ol",
  "section",
  "span",
  "ul",
] as const;

type StackProps = {
  as?: (typeof validStackComponents)[number];
} & BoxProps;

export const Stack = ({
  as = "div",
  children,
  gap = 8,
  ...rest
}: React.PropsWithChildren<StackProps>) => {
  return (
    <Box as={as} display="flex" flexDirection={"column"} gap={gap} {...rest}>
      {children}
    </Box>
  );
};
