import { PropsWithChildren } from "react";
import { Box, BoxProps } from "../Box/Box";

export type CardProps = BoxProps;

export const Card = ({
  as = "div",
  children,
  gap = 8,
  ...rest
}: PropsWithChildren<CardProps>) => {
  return (
    <Box
      as={"div"}
      position={"relative"}
      borderRadius={16}
      boxShadow={"medium"}
      padding={24}
      borderWidth={1}
      borderColor={"grey95"}
      {...rest}
    >
      {children}
    </Box>
  );
};
