import React, { forwardRef } from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import {
  headerStyle,
  triggerStyle,
  contentStyle,
  contentTextStyle,
  itemStyle,
  rootStyle,
  chevronStyle,
} from "./accordion.css";
import clsx from "clsx";

const Base = ({
  defaultValue,
  children,
  type = "single",
  className,
  ...props
}: Omit<AccordionPrimitive.AccordionSingleProps, "type"> & {
  type?: "single";
}) => (
  <AccordionPrimitive.Root
    className={clsx(rootStyle, className)}
    defaultValue={defaultValue ? defaultValue : undefined}
    collapsible
    type={type}
    {...props}
  >
    {children}
  </AccordionPrimitive.Root>
);

export type ItemProps = {
  header: string;
  children: React.ReactNode;
} & AccordionPrimitive.AccordionItemProps;

const Item = ({ children, header, value, ...props }: ItemProps) => (
  <AccordionPrimitive.Item className={itemStyle} value={value} {...props}>
    <AccordionTrigger>{header}</AccordionTrigger>
    <AccordionContent>{children}</AccordionContent>
  </AccordionPrimitive.Item>
);

const AccordionTrigger = forwardRef<HTMLDivElement, any>(
  ({ children, ...props }, forwardedRef) => (
    <AccordionPrimitive.Header className={headerStyle}>
      <AccordionPrimitive.Trigger
        className={triggerStyle}
        {...props}
        ref={forwardedRef}
      >
        {children}
        <ChevronDownIcon className={chevronStyle} aria-hidden />
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  )
);

const AccordionContent = React.forwardRef<HTMLDivElement, any>(
  ({ children, ...props }, forwardedRef) => (
    <AccordionPrimitive.Content
      className={contentStyle}
      {...props}
      ref={forwardedRef}
    >
      <div className={contentTextStyle}>{children}</div>
    </AccordionPrimitive.Content>
  )
);

export const Accordion = {
  Base,
  Item,
};
