import { ReactNodeNoStrings } from "../../types";
import { Box, BoxProps } from "../Box/Box";

export const validInlineComponents = [
  "a",
  "article",
  "div",
  "form",
  "header",
  "label",
  "li",
  "main",
  "section",
  "span",
] as const;

type InlineProps = {
  as?: typeof validInlineComponents[number];
  children: ReactNodeNoStrings;
} & BoxProps;

export const Inline = ({
  as = "div",
  children,
  gap = 8,
  ...rest
}: React.PropsWithChildren<InlineProps>) => {
  return (
    <Box as={as} display="flex" flexDirection={"row"} gap={gap} {...rest}>
      {children}
    </Box>
  );
};
