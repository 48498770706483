import Image, { ImageProps } from "next/image";
import { sprinkles } from "../../sprinkles.css";

type ResponsiveImageProps = {
  /**
   * Responsive Image source going from mobile, to tablet, to desktop
   **/
  src: [ImageProps["src"], ImageProps["src"], ImageProps["src"]];
} & Omit<ImageProps, "src">;

export const ResponsiveImage = ({ src, ...rest }: ResponsiveImageProps) => {
  return (
    <>
      {src.map((src, index) =>
        src ? (
          <Image
            key={index}
            src={src}
            {...rest}
            className={sprinkles({
              display: [
                index === 0 ? "block" : "none",
                index === 1 ? "block" : "none",
                index === 2 ? "block" : "none",
              ],
            })}
          />
        ) : null
      )}
    </>
  );
};
