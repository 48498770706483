import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group";
import { containerStyle, itemStyle } from "./toggleGroup.css";
import clsx from "clsx";

//based on https://www.radix-ui.com/docs/primitives/components/toggle-group

const Base = ({
  children,
  type = "single",
  className,
  ...props
}: Omit<ToggleGroupPrimitive.ToggleGroupSingleProps, "type"> & {
  type?: "single";
}) => (
  <ToggleGroupPrimitive.Root
    className={clsx(containerStyle, className)}
    {...props}
    type={type}
  >
    {children}
  </ToggleGroupPrimitive.Root>
);

const Item = ({
  children,
  className,
  ...props
}: ToggleGroupPrimitive.ToggleGroupItemProps) => (
  <ToggleGroupPrimitive.Item className={clsx(itemStyle, className)} {...props}>
    {children}
  </ToggleGroupPrimitive.Item>
);

export const ToggleGroup = {
  Base,
  Item,
};
