import * as SelectPrimitives from "@radix-ui/react-select";
import clsx from "clsx";
import { ReactNode } from "react";
import { Text } from "../Text/Text";
import { content, item, trigger, viewport } from "./select.css";

type SelectProps = {
  children: ReactNode;
} & Omit<SelectPrimitives.SelectProps, "open">;

/**
 * Basic Dropdown Menu.
 * Based on:
 * https://www.radix-ui.com/docs/primitives/components/dropdown-menu
 */
const Base = ({ children, ...props }: SelectProps) => {
  return <SelectPrimitives.Root {...props}>{children}</SelectPrimitives.Root>;
};

const Item = ({
  className,
  children,
  ...rest
}: SelectPrimitives.SelectItemProps) => (
  <SelectPrimitives.Item {...rest} className={item}>
    <SelectPrimitives.ItemText>
      <Text>{children}</Text>
    </SelectPrimitives.ItemText>
    <SelectPrimitives.ItemIndicator />
  </SelectPrimitives.Item>
);

const Trigger = (props: SelectPrimitives.SelectValueProps) => (
  <SelectPrimitives.Trigger className={trigger}>
    <SelectPrimitives.Value {...props} />
  </SelectPrimitives.Trigger>
);

const Content = ({
  className,
  children,
  ...rest
}: SelectPrimitives.SelectContentProps) => (
  <SelectPrimitives.Portal>
    <SelectPrimitives.Content className={clsx(content, className)} {...rest}>
      <SelectPrimitives.Viewport>{children}</SelectPrimitives.Viewport>
    </SelectPrimitives.Content>
  </SelectPrimitives.Portal>
);

type StyledSelectProps = {
  children: ReactNode;
  placeholder: SelectPrimitives.SelectValueProps["placeholder"];
  className?: string;
} & SelectPrimitives.SelectProps;

/**
 * Styled select component.
 * Takes a list of `Select.Item` as children.
 */
export const StyledSelect = ({
  placeholder,
  children,
  className,
  ...props
}: StyledSelectProps) => (
  <SelectPrimitives.Root {...props}>
    <SelectPrimitives.Trigger className={clsx([trigger, className])}>
      <SelectPrimitives.Value placeholder={placeholder} />
      <SelectPrimitives.Icon />
    </SelectPrimitives.Trigger>
    <SelectPrimitives.Portal>
      <SelectPrimitives.Content className={content}>
        <SelectPrimitives.ScrollUpButton />
        <SelectPrimitives.Viewport className={viewport}>
          {children}
        </SelectPrimitives.Viewport>
        <SelectPrimitives.ScrollDownButton />
        <SelectPrimitives.Arrow />
      </SelectPrimitives.Content>
    </SelectPrimitives.Portal>
  </SelectPrimitives.Root>
);

/**
 * Component primitives
 */
export const Select = {
  Base,
  Trigger,
  Item,
  Content,
};
