import * as SliderPrimitive from "@radix-ui/react-slider";
import { rootStyle, trackStyle, rangeStyle, thumbStyle } from "./slider.css";

// based on https://www.radix-ui.com/docs/primitives/components/slider

export const Slider = (props: SliderPrimitive.SliderProps) => (
  <SliderPrimitive.Root className={rootStyle} {...props}>
    <SliderPrimitive.Track className={trackStyle}>
      <SliderPrimitive.Range className={rangeStyle} />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb className={thumbStyle} />
  </SliderPrimitive.Root>
);
