import { Box, Text } from "@packages/ui";
import { useTranslations } from "@services/strings";

export const Fallback = () => {
  const { t } = useTranslations();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ height: "100vh", width: "100vw" }}
    >
      <Text fontSize={24} textAlign="center">
        {t("common.error.generic")}
      </Text>
    </Box>
  );
};
