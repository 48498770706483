import clsx, { ClassValue } from "clsx";
import { createElement, forwardRef } from "react";
import { sprinkles, Sprinkles } from "../../sprinkles.css";

type HTMLProperties = Omit<
  React.AllHTMLAttributes<HTMLElement>,
  "as" | "className" | "color" | "height" | "width"
>;

export type BoxProps = Sprinkles &
  HTMLProperties & {
    as?: React.ElementType;
    className?: ClassValue;
  };

export const Box = forwardRef<HTMLElement, BoxProps>(
  ({ as = "div", className, ...props }: BoxProps, ref) => {
    const atomProps: Record<string, unknown> = {};
    const nativeProps: Record<string, unknown> = {};

    for (const key in props) {
      if (sprinkles.properties.has(key as keyof Sprinkles)) {
        atomProps[key] = props[key as keyof typeof props];
      } else {
        nativeProps[key] = props[key as keyof typeof props];
      }
    }

    const atomicClasses = sprinkles({
      ...atomProps,
    });

    return createElement(as, {
      className: clsx(atomicClasses, className),
      ...nativeProps,
      ref,
    });
  }
);
