import { forwardRef } from "react";
import { Text, TextProps } from "../Text/Text";

export const validHeadingTags = ["h1", "h2", "h3"] as const;
type ValidHeadingTag = (typeof validHeadingTags)[number];

const variants = ["heading", "section-heading"] as const;
type Variant = (typeof variants)[number];

export type HeadingProps = { as?: ValidHeadingTag; variant?: Variant } & Omit<
  TextProps,
  "as" | "variant"
>;

export const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ variant = "section-heading", ...props }: HeadingProps, ref) => (
    <Text variant={variant} {...props} ref={ref} />
  )
);
