import * as TabPrimitives from "@radix-ui/react-tabs";
import clsx from "clsx";
import { forwardRef } from "react";
import { tab, tabList } from "./tabs.css";

/**
 * Tab panels
 * Based on:
 * https://www.radix-ui.com/docs/primitives/components/tabs
 */

const Base = TabPrimitives.Root;
const TabList = forwardRef<HTMLDivElement, TabPrimitives.TabsListProps>(
  ({ className, ...props }, ref) => (
    <TabPrimitives.List
      {...props}
      className={clsx(tabList, className)}
      ref={ref}
    />
  )
);

const Tab = forwardRef<HTMLButtonElement, TabPrimitives.TabsTriggerProps>(
  ({ className, ...props }, ref) => (
    <TabPrimitives.Trigger
      {...props}
      className={clsx(tab, className)}
      ref={ref}
    />
  )
);
const Content = TabPrimitives.Content;

export const Tabs = {
  Base,
  TabList,
  Tab,
  Content,
};
