/**
 * Design Tokens
 */

/**
 * Color tokens
 */

const base = {
  white: "#ffffff",
  black: "#000000",
  transparent: "transparent",
};

const grey = {
  grey40: "hsl(216, 10%, 40%)",
  grey60: "hsl(216, 10%, 60%)",
  grey75: "hsl(216, 10%, 75%)",
  grey90: "hsl(216, 10%, 90%)",
  grey95: "hsl(216, 10%, 95%)",
  grey98: "hsl(216, 10%, 98%)",
};

const blue = {
  blue20: "hsl(216, 38%, 20%)",
  blue30: "hsl(216, 38%, 30%)",
  blue96: "hsl(216, 38%, 96%)",
};

const cyan = {
  cyan200: "#A5F3FC",
  cyan600: "#0891B2",
};

const orange = {
  orange50: "#FFF7ED",
  orange100: "#FFEDD5",
  orange300: "#FDBA74",
  orange400: "#FB923C",
  orange600: "#EA580C",
  // Deprecated, please don't use
  orange40: "hsl(35, 97%, 40%)",
  orange60: "hsl(35, 97%, 60%)",
  orange70: "hsl(35, 97%, 70%)",
  orange80: "hsl(35, 97%, 80%)",
  orange85: "hsl(35, 97%, 85%)",
  orange95: "hsl(35, 97%, 95%)",
};

const seaBlue = {
  seaBlue70: "hsl(190, 31%, 70%)",
  seaBlue85: "hsl(190, 31%, 85%)",
  seaBlue95: "hsl(190, 31%, 95%)",
};

const seaGreen = {
  seaGreen60: "hsl(161, 44%, 60%)",
  seaGreen95: "hsl(161, 44%, 95%)",
};

const slate = {
  slate50: "#F8FAFC",
  slate100: "#F1F5F9",
  slate200: "#E2E8F0",
  slate300: "#CBD5E1",
  slate500: "#64748B",
  slate600: "#475569",
  slate700: "#334155",
  slate800: "#1E293B",
};

const sky = {
  sky100: "#E0F2FE",
  sky200: "#BAE6FD",
};

const red = {
  red100: "#FEE2E2",
  red500: "#EF4444",
};

const stone = {
  stone300: "#D6D3D1",
  stone600: "#57534E",
};

const color = {
  ...base,
  ...slate,
  ...stone,
  ...grey,
  ...blue,
  ...orange,
  ...seaBlue,
  ...seaGreen,
  ...slate,
  ...cyan,
  ...sky,
  ...red,
} as const;

export type Color = keyof typeof color;

/**
 * Spacing tokens
 */

const space = {
  bleed24: "-24px",
  0: "0px",
  4: "4px",
  8: "8px",
  16: "16px",
  20: "20px",
  24: "24px",
  28: "28px",
  32: "32px",
  36: "36px",
  40: "40px",
  48: "48px",
  56: "56px",
  64: "64px",
  68: "68px",
  72: "72px",
  80: "80px",
  84: "84px",
  96: "96px",
  104: "104px",
  120: "120px",
  144: "144px",
};

/**
 * Size tokens
 */

const size = {
  "50%": "50%",
  "100%": "100%",
  0: "0px",
  2: "2px",
  4: "4px",
  32: "32px",
  44: "44px",
  48: "48px",
  56: "56px",
  64: "64px",
  72: "72px",
  80: "80px",
  200: "200px",
  256: "256px",
  300: "300px",
  512: "512px",
  240: "240px",
  400: "400px",
  480: "480px",
  800: "800px",
  /** Deprecated, please don't use */
  54: "54px",
  68: "68px",
};

/**
 * Border Radius tokens
 */

const borderRadius = {
  "50%": "50%",
  0: "0px",
  2: "2px",
  4: "4px",
  8: "8px",
  16: "16px",
  32: "23px",
};

/**
 * Typography tokens
 */

const fontFamily = {
  "sans-serif": "'Poppins', sans-serif",
};

const fontSize = {
  12: "12px",
  14: "14px",
  16: "16px",
  18: "18px",
  20: "20px",
  24: "24px",
  28: "28px",
  32: "32px",
  40: "40px",
  56: "56px",
  64: "64px",
};

const fontWeight = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

/**
 * Breakpoint tokens, used to create media queries suitable for:
 * - small / mobile (max-width: 768px)
 * - medium / tablet (min-width: 768px) and (max-width: 1024px)
 * - large / desktop  (min-width: 1024px) and (max-width: 1280px)
 * - xl / wide (min-width: 1280px)
 */

const breakpoint = {
  small: 0,
  medium: 768,
  large: 1024,
  xl: 1280,
} as const;

export type Breakpoint = keyof typeof breakpoint;
export const breakpointNames = Object.keys(breakpoint) as Breakpoint[];

/**
 * Elevation tokens
 */

const elevation = {
  none: "none",
  medium:
    "0px 0px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);",
  large:
    "0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);",
};

// export
export {
  borderRadius,
  breakpoint,
  color,
  elevation,
  fontFamily,
  fontSize,
  fontWeight,
  size,
  space,
};
