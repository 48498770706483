import { Stack } from "../../layout";
import { Spinner } from "./Spinner";

export interface LoadingOverlayProps {
  isLoading: boolean;
}

export const LoadingOverlay = ({ isLoading }: LoadingOverlayProps) => (
  <>
    {isLoading ? (
      <>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "white",
            opacity: 0.5,
            zIndex: 1,
            pointerEvents: "none",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            gap={24}
            width={300}
          >
            <Spinner />
          </Stack>
        </div>
      </>
    ) : null}
  </>
);
