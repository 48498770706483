import clsx, { ClassValue } from "clsx";
import { createElement, forwardRef, ReactNode } from "react";
import { Sprinkles, sprinkles } from "../../sprinkles.css";
import merge from "lodash.merge";

export const validTextTags = ["h1", "h2", "h3", "label", "span", "p"] as const;
type ValidTextTag = (typeof validTextTags)[number];

const variants = [
  "regular",
  "eyebrow",
  "section-heading",
  "heading",
  "subtitle",
] as const;
type Variant = (typeof variants)[number];

const variantMap: Record<
  Variant,
  Omit<TextProps, "children"> & { as: ValidTextTag }
> = {
  regular: {
    fontSize: 16,
    fontWeight: "regular",
    as: "span",
  },
  eyebrow: {
    fontSize: [16, 20],
    textTransform: "uppercase",
    fontWeight: "semiBold",
    letterSpacing: "1px",
    as: "span",
  },
  heading: {
    fontSize: [40, 64],
    lineHeight: "1.125",
    fontWeight: "semiBold",
    as: "h2",
  },
  "section-heading": {
    fontSize: [32, 40],
    lineHeight: "1.125",
    fontWeight: "semiBold",
    as: "h2",
  },
  subtitle: {
    fontSize: [16, 24],
    fontWeight: "regular",
    color: "slate500",
    as: "span",
  },
};

export type TextProps = {
  children: ReactNode;
  /**
   * if you supply a variant, it will override the default styles.
   * Adding additional styles will override the variant styles.
   */
  variant?: Variant;
  className?: ClassValue;
  fontSize?: Sprinkles["fontSize"];
  fontWeight?: Sprinkles["fontWeight"];
  textAlign?: Sprinkles["textAlign"];
  textDecoration?: Sprinkles["textDecoration"];
  textTransform?: Sprinkles["textTransform"];
  letterSpacing?: Sprinkles["letterSpacing"];
  lineHeight?: Sprinkles["lineHeight"];
  color?: Sprinkles["color"];
  as?: ValidTextTag;
  style?: React.CSSProperties;
};

export const Text = forwardRef(
  (
    {
      variant = "regular",
      className,
      children,
      fontSize,
      fontWeight,
      color,
      textAlign,
      textDecoration,
      textTransform,
      letterSpacing,
      lineHeight,
      style,
      ...rest
    }: TextProps,
    ref
  ) => {
    const { as, ...variantProps } = variantMap[variant];

    const mergedProps = merge(variantProps, {
      fontSize,
      fontWeight,
      textAlign,
      textDecoration,
      textTransform,
      letterSpacing,
      lineHeight,
      color,
    });

    return createElement(as, {
      className: clsx(sprinkles(mergedProps), className),
      style,
      children,
      ref,
      ...rest,
    });
  }
);
