import * as DropdownMenuPrimitives from "@radix-ui/react-dropdown-menu";
import clsx from "clsx";
import { ReactNode } from "react";
import { content, item } from "./dropdown-menu.css";

type DropdownMenuProps = {
  children: ReactNode;
} & Omit<DropdownMenuPrimitives.DropdownMenuProps, "open">;

/**
 * Basic Dropdown Menu.
 * Based on:
 * https://www.radix-ui.com/docs/primitives/components/dropdown-menu
 */
const Base = ({ children, ...props }: DropdownMenuProps) => {
  return (
    <DropdownMenuPrimitives.Root {...props}>
      {children}
    </DropdownMenuPrimitives.Root>
  );
};

export const Item = ({
  className,
  ...rest
}: DropdownMenuPrimitives.MenuItemProps) => (
  <DropdownMenuPrimitives.Item className={clsx(item, className)} {...rest} />
);

export const Trigger = ({
  asChild = true,
  ...rest
}: DropdownMenuPrimitives.DropdownMenuTriggerProps) => (
  <DropdownMenuPrimitives.Trigger asChild={asChild} {...rest} />
);

export const Content = ({
  className,
  sideOffset = 8,
  collisionPadding = 16,
  ...rest
}: DropdownMenuPrimitives.MenuContentProps) => (
  <DropdownMenuPrimitives.Portal>
    <DropdownMenuPrimitives.Content
      className={clsx(content, className)}
      sideOffset={sideOffset}
      collisionPadding={collisionPadding}
      {...rest}
    />
  </DropdownMenuPrimitives.Portal>
);

export const DropdownMenu = {
  Base,
  Trigger,
  Item,
  Content,
};
