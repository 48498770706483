import {
  ComponentProps,
  forwardRef,
  ForwardRefRenderFunction,
  HTMLInputTypeAttribute,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { ClassValue, clsx } from "clsx";
import { inputBase } from "./styles.css";
import { Sprinkles, sprinkles } from "../../sprinkles.css";

const selectionTypes: readonly HTMLInputTypeAttribute[] = [
  "text",
  "search",
  "url",
  "tel",
  "password",
] as const;

const isSelectionType = (type: unknown): Boolean =>
  typeof type === "string" &&
  selectionTypes.indexOf(type as HTMLInputTypeAttribute) > -1;

type InputSize = Sprinkles["size"];

export interface InputHandle {
  focus: () => void;
  setSelectionRange: (start: number, end: number) => void;
}

export type InputProps = {
  className?: ClassValue;
  size?: InputSize;
} & Omit<ComponentProps<"input">, "size">;

const BaseInput: ForwardRefRenderFunction<InputHandle, InputProps> = (
  { className, size = 64, ...props },
  forwardedRef
) => {
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!isSelectionType(props.type) || !ref.current) {
      return;
    }

    const index = ref.current.value.length;
    ref.current.setSelectionRange(index, index);
  }, [ref]);

  useImperativeHandle(
    forwardedRef,
    (): InputHandle =>
      ref.current ?? {
        focus: () => void 0,
        setSelectionRange: () => void 0,
      },
    [ref.current]
  );

  return (
    <input
      {...props}
      className={clsx(
        inputBase,
        sprinkles({
          height: size,
        }),
        className
      )}
      ref={ref}
    />
  );
};

export const Input = forwardRef(BaseInput);
