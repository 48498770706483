import { ClassValue } from "clsx";
import { Box, BoxProps } from "../../layout";
import { Sprinkles } from "../../sprinkles.css";
import { Text } from "../Text/Text";

export type PillTones = "green" | "orange" | "grey" | "blue";

const pillToneMap: Record<
  PillTones,
  {
    backgroundColor: Sprinkles["color"];
    color: Sprinkles["color"];
  }
> = {
  green: {
    backgroundColor: "seaGreen95",
    color: "seaGreen60",
  },
  orange: {
    backgroundColor: "orange95",
    color: "orange40",
  },
  grey: {
    backgroundColor: "grey95",
    color: "grey60",
  },
  blue: {
    backgroundColor: "blue96",
    color: "blue30",
  },
};

export type PillProps = {
  children: React.ReactNode;
  tone?: PillTones;
  className?: ClassValue;
};

export type SolidPillProps = PillProps & BoxProps;

export const Pill = ({
  children,
  color,
  className,
  tone = "green",
  ...rest
}: SolidPillProps) => {
  return (
    <Box
      as={"span"}
      className={className}
      minHeight={32}
      borderRadius={16}
      paddingX={16}
      paddingY={8}
      display={"flex"}
      placeItems={"center"}
      background={pillToneMap[tone].backgroundColor}
      {...rest}
    >
      <Text color={pillToneMap[tone].color} fontSize={14} fontWeight={"medium"}>
        {children}
      </Text>
    </Box>
  );
};
