import { clsx, ClassValue } from "clsx";
import { Box } from "../../layout";
import { Sprinkles } from "../../sprinkles.css";
import { avatarImg } from "./style.css";

export type AvatarProps = {
  src: string;
  alt?: string;
  size?: Sprinkles["size"];
  className?: ClassValue;
};

export const Avatar = ({
  src,
  alt = "avatar",
  className,
  size = 80,
}: AvatarProps) => {
  return (
    <Box
      className={clsx(className)}
      as="span"
      borderRadius={"50%"}
      overflow={"hidden"}
      width={size}
      height={size}
      background={"grey98"}
      boxShadow={"medium"}
    >
      <img className={avatarImg} src={src} alt={alt} />
    </Box>
  );
};
