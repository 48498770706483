import clsx, { ClassValue } from "clsx";
import React, { createElement, forwardRef } from "react";
import { disabled } from "../../utility.css";
import {
  ButtonVariants,
  filledButtonVariants,
  ghostButton,
  linkButton,
} from "./styles.css";

export type ButtonProps = {
  children: React.ReactNode;
  className?: ClassValue;
  isDisabled?: boolean;
  as?: "button" | "a" | "span";
} & React.ComponentProps<"button">;

export type SolidButtonProps = ButtonProps & ButtonVariants;

export const Button = forwardRef(
  (
    { color, as = "button", className, isDisabled, ...rest }: SolidButtonProps,
    ref
  ) => {
    return createElement(as, {
      className: clsx(
        filledButtonVariants({
          color,
        }),
        { [disabled]: isDisabled },
        className
      ),
      disabled: isDisabled,
      ref,
      ...rest,
    });
  }
);

export type GhostButtonProps = ButtonProps;

export const GhostButton = forwardRef(
  (
    { as = "button", isDisabled, className, ...rest }: GhostButtonProps,
    ref
  ) => {
    return createElement(as, {
      className: clsx(ghostButton, { [disabled]: isDisabled }, className),
      disabled: isDisabled,
      ref,
      ...rest,
    });
  }
);

export type LinkButtonProps = ButtonProps;

export const LinkButton = forwardRef(
  (
    { as = "button", isDisabled, className, ...rest }: GhostButtonProps,
    ref
  ) => {
    return createElement(as, {
      className: clsx(linkButton, { [disabled]: isDisabled }, className),
      disabled: isDisabled,
      ref,
      ...rest,
    });
  }
);
