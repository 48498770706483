import clsx, { ClassValue } from "clsx";
import { separator } from "./style.css";

type SeparatorProps = {
  className?: ClassValue;
};

export const Separator = ({ className }: SeparatorProps) => {
  return <hr className={clsx(separator, className)} />;
};
