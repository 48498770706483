import * as DialogPrimitives from "@radix-ui/react-dialog";
import { ReactNode } from "react";
import { Heading, HeadingProps } from "../Heading/Heading";
import { content, overlay } from "./dialog.css";

export type DialogProps = {
  children: ReactNode;
  onClose: () => void;
} & DialogPrimitives.DialogProps;

/**
 * Controlled Dialog.
 * Based on:
 * https://www.radix-ui.com/docs/primitives/components/dialog
 */
export const StyledDialog = ({ children, onClose, ...props }: DialogProps) => {
  const handleOnOpenChange = (open: boolean) => {
    if (!open) {
      onClose();
    }
  };

  return (
    <DialogPrimitives.Root onOpenChange={handleOnOpenChange} {...props}>
      <DialogPrimitives.Portal>
        <DialogPrimitives.Overlay className={overlay} />
        <DialogPrimitives.Content className={content}>
          {children}
        </DialogPrimitives.Content>
      </DialogPrimitives.Portal>
    </DialogPrimitives.Root>
  );
};

type TitleProps = DialogPrimitives.DialogTitleProps & {
  headingProps?: Omit<HeadingProps, "children">;
};

const Base = DialogPrimitives.Root;

const Portal = DialogPrimitives.Portal;
const Overlay = DialogPrimitives.Overlay;


const Trigger = ({
  children,
  ...props
}: DialogPrimitives.DialogTriggerProps) => (
  <DialogPrimitives.Trigger {...props} asChild={true}>
    {children}
  </DialogPrimitives.Trigger>
);

const Content = ({
  children,
  ...props
}: DialogPrimitives.DialogContentProps) => (
  <DialogPrimitives.Content {...props}>{children}</DialogPrimitives.Content>
);

const Title = ({ children, headingProps, ...props }: TitleProps) => (
  <DialogPrimitives.DialogTitle {...props} asChild={true}>
    <Heading {...headingProps}>{children}</Heading>
  </DialogPrimitives.DialogTitle>
);

const Close = ({ children, ...props }: DialogPrimitives.DialogCloseProps) => (
  <DialogPrimitives.Close {...props} asChild={true}>
    {children}
  </DialogPrimitives.Close>
);

export const Dialog = {
  Portal,
  Overlay,
  Base,
  Content,
  StyledDialog,
  Title,
  Close,
  Trigger,
};
